<template>
  <a-drawer
    title="报修单详情"
    :maskClosable="false"
    width="850"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :validateOnRuleChange="true"
        :model="form"
        :rules="formRules"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="报修时间">
          <span>{{moment(form.createTime).format("YYYY-MM-DD HH:mm:ss")}}</span>
        </a-form-model-item>
        <a-form-model-item label="报修类型">
          <span>{{getRepairsType(form.type)}}</span>
        </a-form-model-item>
        <a-form-model-item label="联系人">
          <span>{{form.contact}}</span>
        </a-form-model-item>
        <a-form-model-item label="电话">
          <span>{{form.contact}}</span>
        </a-form-model-item>
        <a-form-model-item label="地址">
          <span>{{form.address}}</span>
        </a-form-model-item>
        <a-form-model-item label="问题描述">
          <span>{{form.description}}</span>
        </a-form-model-item>
        <a-form-model-item label="合同号">
          <span>{{form.contractNo}}</span>
        </a-form-model-item>
        <a-form-model-item label="项目名称">
          <span>{{form.projectName}}</span>
        </a-form-model-item>
        <a-form-model-item label="紧急度">
          <span>{{getUrgencyTitle(form.urgency)}}</span>
        </a-form-model-item>
        <a-form-model-item label="当前状态">
          <span>{{getCurrentStatus(form.status)}}</span>
        </a-form-model-item>
        <a-form-model-item label="关联工单" prop="taskId">
          <a-select
            show-search
            v-model="form.taskId"
            placeholder="请选择要关联的工单"
          >
            <a-select-option
              v-for="(t, index) in taskListData"
              :value="t.taskId"
              :key="index"
              >{{ t.taskNo }}-{{ getRepairsType(t.taskType) }}-{{t.primeEngineer.username}}</a-select-option
            >
          </a-select>
          <!-- <task-input-select
          v-model="form.taskId"
          :defaultProjectList="defaultProjectList"
          placeholder="关联工单"
          >

          </task-input-select> -->
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="drawer-bootom-button">
			<a-button @click="handleClose()" style="margin-right: 0.8rem">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">{{
        handleType === 0 ? "保存" : "编辑"
      }}</a-button>
    </div>
  </a-drawer>
</template>
<script>
import TaskInputSelect from "../../business-common/TaskInputSelect";
import {getDeptRootId} from './../../../utils/tools'
import moment from "moment";

const formRules = {
  taskId: [{ required: true, message: "请选择需要关联的工单", trigger: "blur" }],
};
export default {
  name: "repairsDetail",
  components: {TaskInputSelect},
  data() {
    return {
      moment,
      taskListData: [],
      deptTreeData: [],
      form: {
        contact: undefined,
        phone: undefined,
        address: undefined,
        description: undefined,
        contractNo: undefined,
        projectName: undefined,
        status: undefined,
        urgency: undefined,
        taskId: undefined
      },
      loading: false,
      formRules,
      titleText: ["新增技能", "修改技能"],
      handleType: 0,
      defaultProjectList: [],
      drawerShow: false,
    };
  },
  props: {
    repairsDetailVisiable: {
      default: false,
    },
    status: {
      type: Array,
      required: true,
      default: () => [],
    },
    urgency: {
      type: Array,
      required: true,
      default: () => [],
    },
    taskType: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    topDeptId() {
      return getDeptRootId('inner')
    },
  },
  mounted() {
    this.$get("dept", { rootDeptIds: this.topDeptId }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
  },
  methods: {
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
      this.reset();
    },
    setForm(data) {
      for (let key in data) {
        this.form[key] = data[key];
      }
      this.form.address = data.province + data.city + data.area + data.address;
      if (data.taskId != undefined) {
        this.defaultProjectList = [{taskId: data.taskId, taskNo: data.taskNo, projectName: data.projectName, contractNo: data.contractNo}]
      }
    this.$get(`task/list-by-project?projectId=${data.projectId}`).then((r) => {
      this.taskListData = r.data.data;
    });
      this.drawerShow = true;
    },
    handleAcceptDeptChange(value, label) {
      console.log("deptId ==>", value, label[0]);
      this.form.deptId = +value;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let skill = JSON.parse(JSON.stringify(this.form));
          let id = skill.id
          let taskId = skill.taskId

          //新增操作
          this.$put(`repairs/assign-task/${id}/${taskId}`).then((r) => {
              this.$message.success(r.data.message);
              this.handleClose();
              this.$emit("success");
              console.log(r);
            });
        }
      });
      console.log("submit");
    },
    getUrgencyTitle(keyy) {
      const item = this.urgency.find(o=>o.keyy == keyy)
      return item == undefined ? '' : item.valuee
    },
    getRepairsType(keyy) {
      const item = this.taskType.find(o=>o.keyy == keyy)
      return item == undefined ? '' : item.valuee
    },
    getCurrentStatus(keyy) {
      const item = this.taskType.find(o=>o.keyy == keyy)
      return item == undefined ? '' : item.valuee
    },
  },
};
</script>